import React from 'react'
import classnames from 'classnames'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Container, Heading, BlueText, Button } from '_atoms'

import styles from './styles.module.css'

const DidntFind = () => (
  <Container className={styles.didntFindContainer}>
    <div className={styles.titleContainer}>
      <Heading type="h2" className={classnames(styles.title)}>
        <FormattedMessage id="careers.didntFind.title" />
        <BlueText squared>?</BlueText>
      </Heading>
    </div>
    <div className={styles.subTitleContainer}>
      <Heading type="h3" className={styles.subTitle}>
        <FormattedMessage id="careers.didntFind.subTitle" />
      </Heading>

      <a href="https://cheesecakelabs.hire.trakstar.com/jobs/fk0f6lp/" id="careersDidntFindButton">
        <Button type="primary" className={styles.applyButton}>
          <FormattedMessage id="careers.didntFind.button" />
        </Button>
      </a>
    </div>
  </Container>
)
export default DidntFind
