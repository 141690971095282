export function setCssVar(variables, namespace) {
  const keys = Object.keys(variables)

  const namespaceValue = namespace ? `-${namespace}` : ''

  const transformedVariables = keys.reduce((acc, currKey) => {
    return { ...acc, [`--root${namespaceValue}-${currKey}`]: variables[currKey] }
  }, {})

  return { style: transformedVariables }
}
