import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import Img from 'gatsby-image'

import styles from './styles.module.css'

const Image = ({ className, src, fluid, alt, srcSet, radius, circular, eager, ...props }) => {
  const baseProps = {
    className: classNames(styles.image, { [styles.circular]: circular }, className),
    style: radius ? { borderRadius: radius } : {},
    srcSet: srcSet || undefined,
    loading: eager ? 'eager' : 'lazy',
    ...props,
  }

  const imageProps = Object.assign(baseProps, fluid ? { fluid } : { src })
  if (fluid) {
    return <Img {...imageProps} alt={alt} />
  }
  return <img alt={alt} {...imageProps} />
}

Image.propTypes = {
  src: PropTypes.string,
  fluid: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }),
  alt: PropTypes.string.isRequired,
  radius: PropTypes.number,
  circular: PropTypes.bool,
  /**
   * @expected 'file/path/img@2x.png 2x, file/path/img@3x.png 3x'
   */
  srcSet: PropTypes.string,
  className: PropTypes.string,
  eager: PropTypes.bool,
}

Image.defaultProps = {
  src: undefined,
  fluid: undefined,
  srcSet: '',
  className: '',
  radius: 0,
  circular: false,
  eager: false,
}

export default Image
