import React, { useState } from 'react'
import { Row } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'

import styles from './styles.module.css'

const CustomAccordion = ({ key, title, content, startsOpen, href = '#' }) => {
  const [accordionStatus, setAccordionStatus] = useState(startsOpen)

  const onClicked = () => {
    setAccordionStatus(!accordionStatus)
  }

  return (
    <div
      className={`${styles.accordion} ${accordionStatus ? styles.uncollapsed : styles.collapsed}`}
    >
      <button type="button" onClick={onClicked}>
        <Row className={styles.accordionTitle}>
          <span className={styles.accordionArrow} />
          <span>{title}</span>
        </Row>
      </button>
      <div className={styles.accordionPanel}>
        <p>{content}</p>
        <div className={styles.accordionPanelLink}>
          <Link to={href} key={key}>
            <FormattedMessage id="home.solvingRealProblems.learnMore" />
          </Link>
        </div>
      </div>
    </div>
  )
}

CustomAccordion.defaultProps = {
  startsOpen: false,
}

CustomAccordion.propTypes = {
  title: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  href: PropTypes.object.isRequired,
  startsOpen: PropTypes.object,
}

export default CustomAccordion
