import { useIntl } from 'gatsby-plugin-intl'
import { useCallback } from 'react'

const useIntlNamespace = namespace => {
  const { formatMessage } = useIntl()

  const formatMessageWithNamespace = useCallback(
    messageId => {
      return formatMessage({ id: `${namespace}.${messageId}` })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [namespace]
  )

  return formatMessageWithNamespace
}

export default useIntlNamespace
