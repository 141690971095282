import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import FacebookIcon from '_images/svgs/facebook.inline.svg'
import LinkedinIcon from '_images/svgs/linkedin.inline.svg'
import TwitterIcon from '_images/svgs/twitter.inline.svg'
import InstagramIcon from '_images/svgs/instagram.inline.svg'
import { Container, Text } from '_atoms'

import styles from './styles.module.css'

const cx = classNames.bind(styles)

const Footer = () => {
  const [hovering, setHovering] = useState(false)
  const currentYear = new Date().getFullYear()
  const intl = useIntl()

  return (
    <div id="footer" className={cx('footer', { dark: hovering })}>
      <Container>
        <Link
          to="/contact"
          className={styles.sayHelloButton}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          <Text size="20" color="white">
            <FormattedMessage id="footer.likeWhatYouSee" />
            <span className={styles.squared}>?</span>
          </Text>
          <Text size="56" color="white" bold className={styles.sayHelloButtonTitle}>
            <FormattedMessage id="footer.sayHello" />
            <span className={styles.squared}>.</span>
          </Text>
        </Link>

        <div className={styles.subFooter}>
          <div className={styles.subFooterLinks}>
            <Link to="/contact" className="footer.link.location" id="sanFrancisco">
              <Text className={styles.cityLink}>
                <FormattedMessage id="footer.sanFrancisco" />
              </Text>
            </Link>
            <Link to="/contact" className="footer.link.location" id="florianopolis">
              <Text className={styles.cityLink}>
                <FormattedMessage id="footer.florianopolis" />
              </Text>
            </Link>
            <a href="mailto:info@cheesecakelabs.com" className="footer.link.location">
              <Text className={styles.cityLink} color="white">
                info@cheesecakelabs.com
              </Text>
            </a>
          </div>

          <div className={styles.subFooterNetworks}>
            <a
              href="https://www.facebook.com/cheesecakelabs"
              className={styles.subFooterNetworksLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/cheesecake-labs/"
              className={styles.subFooterNetworksLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <LinkedinIcon />
            </a>
            <a
              href="https://twitter.com/cheesecakelabs"
              className={styles.subFooterNetworksLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.instagram.com/cheesecakelabs/"
              className={styles.subFooterNetworksLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <InstagramIcon />
            </a>
          </div>
        </div>

        <div className={styles.bottomRow}>
          {intl.locale === 'pt' ? (
            <a
              href="https://cheesecakelabs.com/blog/br/relatorios-anuais-cheesecake-labs/"
              rel="noopener noreferrer"
              target="_blank"
              className={cx('cityLink', 'reportsLink')}
            >
              Relatórios anuais
            </a>
          ) : (
            <div />
          )}
          <br />
          <div className={styles.copyright}>© {currentYear} Cheesecake Labs, Inc.</div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
