import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { BrandsFluid, BrandsSVG } from '_molecules'
import BlueRectangle from '_images/svgs/blue-rectangle.svg'

import styles from './styles.module.css'

const ShowProjectByBrand = ({ brand, brandClassName, projectClassName, projects }) => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        onClick={({ currentTarget, target }) => {
          const element = currentTarget || target
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }}
        className={styles.showProjectByBrand}
      >
        <BrandsSVG className={classNames(styles.brand, brandClassName)} name={brand} />
        <div className={styles.projectGrid}>
          <i className={classNames(styles.projectLink, styles.blueRectangle)}>
            <BlueRectangle />
          </i>
          {projects.map(project => (
            <a
              rel="noreferrer"
              key={`ShowProjectByBrand-${project.name}`}
              href={project.url}
              className={styles.projectLink}
              target="_blank"
            >
              <BrandsFluid className={projectClassName} name={project.name} />
            </a>
          ))}
        </div>
      </div>
    </>
  )
}

ShowProjectByBrand.propTypes = {
  brand: PropTypes.string.isRequired,
  brandClassName: PropTypes.string.isRequired,
  projectClassName: PropTypes.string.isRequired,
  projects: PropTypes.instanceOf(Array).isRequired,
}

export default ShowProjectByBrand
