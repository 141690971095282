/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby-plugin-intl'

import styles from './styles.module.css'

export const BUTTON_STYLES = ['primary', 'secondary', 'outlineWhite', 'dark']

const buttonLoadingComponent = 'Wait...'

const Button = ({
  type,
  htmlType,
  onClick,
  disabled,
  loading,
  small,
  className,
  children,
  href,
  linkId,
  linkClass,
  isExternalLink,
  ...props
}) => {
  const baseClassnames = [
    styles.btn,
    styles[type],
    { [styles.small]: small },
    { [styles.disabled]: disabled },
    { [styles.loading]: loading },
    className,
  ]

  const buttonProps = {
    type: htmlType,
    onClick,
    className: classNames(baseClassnames),
    disabled: disabled || !!loading,
    ...props,
  }

  const aTagProps = {
    className: classNames([...baseClassnames, styles.aTag, linkClass]),
    ...props,
  }

  const setButtonChildren = () => (loading ? buttonLoadingComponent : children)

  if (href && !isExternalLink) {
    return (
      <Link to={href} id={linkId} className={linkClass}>
        <button {...buttonProps}>
          <span className={styles.btnText}>{setButtonChildren()}</span>
          <span className={styles.btnHighlight} />
        </button>
      </Link>
    )
  }

  if (href && isExternalLink) {
    return (
      <a {...aTagProps} href={href} id={linkId}>
        <span className={styles.btnText}>{setButtonChildren()}</span>
        <span className={styles.btnHighlight} />
      </a>
    )
  }

  return (
    <button {...buttonProps}>
      <span className={styles.btnText}>{setButtonChildren()}</span>
      <span className={styles.btnHighlight} />
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(BUTTON_STYLES),
  htmlType: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  linkId: PropTypes.string,
  linkClass: PropTypes.string,
}

Button.defaultProps = {
  type: 'primary',
  htmlType: 'button',
  onClick: () => {},
  disabled: false,
  isExternalLink: false,
  loading: false,
  small: false,
  className: undefined,
  href: undefined,
  linkId: undefined,
  linkClass: undefined,
}

export default Button
