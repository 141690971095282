import React from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'

import { Text } from '_atoms'
import { useWindowSize } from '_hooks'

import styles from './styles.module.css'

export const ProcessCard = ({ title, text, number, rowWidthBreakingPoint }) => {
  const [screenWidth] = useWindowSize()
  const isUnderBreakingPoint = screenWidth < rowWidthBreakingPoint

  return (
    <div className={styles.wrapperContainer}>
      <Row className={styles.wrapperHeader} columns={2}>
        <Col span={isUnderBreakingPoint ? 2 : 3}>
          <div className={styles.numberContainer}>
            <Text className={styles.numberText}>{number}</Text>
          </div>
        </Col>
        <Col span={isUnderBreakingPoint ? 10 : 9}>
          <div className={styles.wrapperArrow}>
            <div className={styles.arrow}>
              <div className={styles.arrowLine} />
              <div className={styles.arrowHead} />
            </div>
          </div>
        </Col>
      </Row>
      <div className={styles.wrapperBody}>
        <Text className={styles.title}>{title}</Text>

        <Text className={styles.text}>{text}</Text>
      </div>
    </div>
  )
}

ProcessCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  rowWidthBreakingPoint: PropTypes.number,
}

ProcessCard.defaultProps = {
  rowWidthBreakingPoint: 1300,
}
export default ProcessCard
