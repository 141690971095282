import React from 'react'
import PropTypes from 'prop-types'

import { Image, Heading, Text } from '_atoms'

import styles from './styles.module.css'

const ImpactCard = ({ icon, title, description }) => (
  <div>
    <div className={styles.iconWrapper}>
      <Image fluid={icon} alt="Impact icon" />
    </div>
    <Heading type="h2" bold className={styles.title}>
      {title}
    </Heading>
    <Text className={styles.description}>{description}</Text>
  </div>
)

ImpactCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default ImpactCard
