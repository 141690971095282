export default [
  {
    text: 'services',
    link: '/services/',
    class: 'servicesMenu',
    proxied: true,
    outline: false,
    hasPrefix: false,
  },
  {
    text: 'portfolio',
    link: '/portfolio/',
    class: 'portfolioMenu',
    proxied: false,
    outline: false,
    hasPrefix: false,
  },
  {
    text: 'about',
    link: '/about/',
    class: 'aboutMenu',
    proxied: false,
    outline: false,
    hasPrefix: false,
  },
  {
    text: 'careers',
    link: '/careers/',
    class: 'careersMenu',
    proxied: false,
    outline: false,
    hasPrefix: false,
  },
  {
    text: 'blog',
    link: '/blog/',
    class: 'blogMenu',
    proxied: true,
    outline: false,
    hasPrefix: true,
  },
  {
    text: 'contact',
    link: '/contact/',
    class: 'contactMenu',
    proxied: false,
    outline: true,
    filled: true,
    hasPrefix: false,
  },
]
