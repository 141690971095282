import React, { useEffect, useRef } from 'react'

import styles from './styles.module.css'

const IphoneMock = () => {
  const video = useRef(null)

  useEffect(() => {
    if (video.current) {
      video.current.play()
    }
  }, [video])

  return (
    <div className={styles.phoneContainer}>
      <div className={styles.phone}>
        <div className={styles.buttons}>
          <div className={styles.left}>
            <div className={styles.button} />
            <div className={styles.button} />
            <div className={styles.button} />
          </div>
          <div className={styles.right}>
            <div className={styles.button} />
          </div>
        </div>
        <div className={styles.camera} />
        <div className={styles.screenContainer}>
          {/* <video ref={video} preload="auto" loop autoPlay muted playsInline controls={false}>
            <source src={videoSrc} type="video/mp4" />
          </video> */}
          <iframe
            title="iphone-ckl-cases-video"
            src="https://player.cloudinary.com/embed/?public_id=iphoneMock&cloud_name=dkhuduyv3&player[controls]=false&player[muted]=true&player[posterOptions][transformation][startOffset]=1&player[autoplay]=true&player[loop]=true"
            width="640"
            height="360"
            style={{
              height: '101%', // 101% to hide the bottom notch (kinda magic number - improviment suggestion: video without bottom notch or remove phone notch)
              width: '100%',
              aspectRatio: 23 / 50,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
            allowFullScreen
            disablePictureInPicture // disable pip to avoid video controls (Doesn't work on Firefox and few other browsers) - Improviment suggestion: Remove via script the controls https://stackoverflow.com/questions/54458516/how-to-disable-picture-in-picture-mode-on-html5-video
            frameBorder="0"
          />
          <div className={styles.notchContainer}>
            <div className={styles.notch} />
          </div>
          <div className={styles.notchBlur} />
        </div>
      </div>
    </div>
  )
}

export default IphoneMock
