import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Img from 'gatsby-image'

import { Text, Image } from '_atoms'

import GlassdoorStars from '../glassdoor-stars'

import styles from './styles.module.css'

const Quote = ({
  text,
  userPhoto,
  user,
  userJob,
  large,
  white,
  dark,
  glassdoorStars,
  useGatsbyImage,
  color,
  mobileComplete,
  center,
  hideUserPhotoMobile,
  commaFormat,
  badge,
  className,
  textClassName,
  userDetailsClassName,
  jobTextClassName,
  imageClassName,
  isQuoteSignPoppins,
}) => {
  const COLORS = {
    default: styles.default,
    vppBluePrimary: styles.vppBluePrimary,
    tapcartPrimary: styles.tapcartPrimary,
    skyroamSecondary: styles.skyroamSecondary,
    lockitronSecondary: styles.lockitronSecondary,
    suPink: styles.suPink,
    greenLight: styles.greenLight,
    eqiPrimary: styles.eqiPrimary,
    woovitAccent: styles.woovitAccent,
    whiteSmoke: styles.whiteSmoke,
    thawQuote: styles.thawQuote,
    main: '', // primary project color, default
  }
  const ImageComponent = useGatsbyImage ? (
    <Img
      fixed={userPhoto}
      className={classNames(styles.userPhoto, imageClassName, {
        [styles.hiddenMobile]: hideUserPhotoMobile,
      })}
      objectFit="cover"
      objectPosition="50% 50%"
      alt={user}
    />
  ) : (
    <Image
      {...userPhoto}
      className={classNames(styles.userPhoto, imageClassName, {
        [styles.hiddenMobile]: hideUserPhotoMobile,
      })}
      alt={user}
      circular
    />
  )

  return (
    <div
      className={classNames(styles.quote, className, {
        [styles.large]: large,
        [styles.white]: white,
        [styles.dark]: dark,
        [styles.complete]: mobileComplete,
        [styles.center]: center,
      })}
    >
      <Text color="white" className={classNames(styles.quoteText, textClassName)}>
        <span
          className={classNames(styles.quoteSign, [color ? COLORS[color] : COLORS.default], {
            [styles.quoteSignPoppins]: isQuoteSignPoppins,
          })}
        >
          &ldquo;
        </span>
        {text || ''}
      </Text>
      <div className={styles.user}>
        {glassdoorStars !== null ? (
          <GlassdoorStars stars={glassdoorStars} />
        ) : (
          <>
            <div
              className={classNames(styles.userPhotoContainer, {
                [styles.hiddenMobile]: hideUserPhotoMobile,
              })}
            >
              {ImageComponent}
            </div>
            <div
              className={classNames(styles.container, {
                [styles.commaFormat]: commaFormat,
              })}
            >
              <div className={userDetailsClassName}>
                <Text color={white ? 'white' : 'default'} className={styles.userName}>
                  {user}
                </Text>
                <Text
                  color={white ? 'white' : 'default'}
                  className={classNames(styles.userJob, jobTextClassName)}
                >
                  {userJob}
                </Text>
              </div>
              <div className={styles.badge}>{badge}</div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  textClassName: PropTypes.string,
  user: PropTypes.string.isRequired,
  userDetailsClassName: PropTypes.string,
  userJob: PropTypes.string.isRequired,
  jobTextClassName: PropTypes.string,
  userPhoto: PropTypes.shape({
    src: PropTypes.string,
    srcSet: PropTypes.string,
  }),
  large: PropTypes.bool,
  white: PropTypes.bool,
  dark: PropTypes.bool,
  useGatsbyImage: PropTypes.bool,
  glassdoorStars: PropTypes.number,
  color: PropTypes.string,
  mobileComplete: PropTypes.bool,
  imageClassName: PropTypes.string,
  center: PropTypes.bool,
  className: PropTypes.string,
  hideUserPhotoMobile: PropTypes.bool,
  isQuoteSignPoppins: PropTypes.bool,
  commaFormat: PropTypes.bool,
  badge: PropTypes.node,
}

Quote.defaultProps = {
  userPhoto: '',
  large: false,
  white: false,
  dark: false,
  center: false,
  useGatsbyImage: false,
  glassdoorStars: null,
  color: '',
  mobileComplete: false,
  imageClassName: undefined,
  className: undefined,
  hideUserPhotoMobile: false,
  commaFormat: false,
  textClassName: undefined,
  jobTextClassName: undefined,
  isQuoteSignPoppins: false,
  userDetailsClassName: undefined,
  badge: undefined,
}

export default Quote
