import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Fade from 'react-reveal/Fade'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { AppContext } from '_context'
import MobileMenuIcon from '_images/svgs/mobile-menu.inline.svg'
import CloseMobileMenuIcon from '_images/svgs/mobile-menu-close.inline.svg'
import navLinks from '_config/nav-links'
import { Brand, Container, Button } from '_atoms'
import { NavLink } from '_molecules'
import { scrollTo } from '_utils/scroll'
import globalStyles from '_config/css-variables'

import styles from './styles.module.css'

const SCROLL_TOP_THRESHOLD = 90

const {
  'color-white': colorWhite,
  'color-primary': colorPrimary,
  'text-color': colorText,
} = globalStyles

const Navbar = ({ light, compact, cta, navbarColorOverride, opacityBg }) => {
  const { navbarColor, navbarLight, navbarCompact, customBrandColor } = useContext(AppContext)
  const [isCompact, setCompact] = useState(false)
  const [isMobileMenuOpen, setMobileMenu] = useState(false)

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    if (isMobileMenuOpen) {
      setCompact(true)
      return
    }

    if (opacityBg) {
      setCompact(y > 1)
    } else {
      setCompact(y > SCROLL_TOP_THRESHOLD)
    }
  }, [compact, isMobileMenuOpen, light, opacityBg, y])

  const brandColor = () => {
    if (isMobileMenuOpen) return 'white'

    if (customBrandColor) return customBrandColor

    return navbarLight || light ? 'blue' : 'white'
  }

  const NavMenu = (
    <ul className={styles.navLinks}>
      {navLinks.map(item => (
        <li key={`navbar-link-${item.link}`} className={styles.navlink}>
          <NavLink
            hasPrefix={item.hasPrefix}
            to={item.link}
            className={item.class}
            isFilled={item.filled}
            isProxied={item.proxied}
            isOutline={item.outline}
          >
            <FormattedMessage id={`navigation.${item.text}`} />
          </NavLink>
        </li>
      ))}
    </ul>
  )

  const onNavCTAClick = () => {
    scrollTo(cta.scrollTo)

    if (isMobileMenuOpen) {
      setMobileMenu(!isMobileMenuOpen)
    }
  }

  const NavCTA = cta && (
    <Button small type="dark" className={styles.ctaButton} onClick={onNavCTAClick} id={cta.id}>
      {cta.text}
    </Button>
  )
  // if navbar bg is light, its color is colorText
  // invert colors to show in hover
  // transparent fallback is colorPrimary
  const colorHoverText = navbarLight === light ? navbarColor : colorWhite

  const variables = {
    '--root-hover-bg': navbarLight === light ? colorWhite : colorText,
    '--root-hover-text': colorHoverText !== 'transparent' ? colorHoverText : colorPrimary,
  }

  const handleNavbarColorOverride = () => {
    const shouldOverride = !!navbarColorOverride && navbarColor === colorPrimary

    return shouldOverride ? navbarColorOverride : navbarColor
  }

  return (
    <div
      className={classNames(
        'navbar',
        styles.navbar,
        opacityBg && !isCompact ? styles.opacityBg : null,
        { [styles.navbarLight]: navbarLight || light },
        { [styles.navbarCompact]: isCompact || navbarCompact || compact },
        { [styles.mobileNavbar]: isMobileMenuOpen }
      )}
      style={{ backgroundColor: handleNavbarColorOverride(), ...variables }}
    >
      <Container>
        <div className={styles.navbarContainer}>
          <a className={styles.brandLink} href="/">
            <Brand compact={isCompact || navbarCompact || compact} color={brandColor()} />
          </a>

          <div className={styles.desktopNavMenu}>{cta ? NavCTA : NavMenu}</div>
          <button
            className={classNames(styles.mobileMenuButton, {
              [styles.active]: isMobileMenuOpen,
            })}
            onClick={() => setMobileMenu(!isMobileMenuOpen)}
            type="button"
          >
            {isMobileMenuOpen ? <CloseMobileMenuIcon /> : <MobileMenuIcon />}
          </button>
        </div>
      </Container>
      <div className={classNames(styles.mobileNavMenu, { [styles.active]: isMobileMenuOpen })}>
        <nav>
          <Fade delay={200} when={isMobileMenuOpen} cascade bottom>
            {cta ? NavCTA : NavMenu}
          </Fade>
        </nav>
      </div>
    </div>
  )
}

Navbar.propTypes = {
  light: PropTypes.bool,
  compact: PropTypes.bool,
  cta: PropTypes.shape({
    text: PropTypes.string,
    scrollTo: PropTypes.string,
    id: PropTypes.string,
  }),
  navbarColorOverride: PropTypes.string,
  opacityBg: PropTypes.bool,
}

Navbar.defaultProps = {
  light: false,
  compact: false,
  cta: undefined,
  navbarColorOverride: undefined,
  opacityBg: undefined,
}

export default Navbar
