import React from 'react'
import PropTypes from 'prop-types'

import { Image, Text } from '_atoms'

import styles from './styles.module.css'

export const CompanyCard = ({ logo, text, link, linkText }) => {
  return (
    <div className={styles.wrapper}>
      <Image alt="" src={logo} className={styles.logo} />
      <Text size="16" className={styles.text}>
        {text}
      </Text>
      <a href={link} target="_blank" className={styles.link} rel="noopener noreferrer">
        {linkText}
      </a>
    </div>
  )
}

CompanyCard.propTypes = {
  logo: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
}

export default CompanyCard
