import axios from 'axios'

import { getCookieValue } from '_utils/get-cookie-value'

export const sendContactForm = (formData, ipAddress, formId) => {
  const parsedFormFields = Object.entries(formData).map(([name, value]) => ({ name, value }))
  const isBrowser = typeof window !== 'undefined'
  const hutk = isBrowser ? getCookieValue('hubspotutk') : null
  const pageUri = isBrowser ? window.location.href : null
  const pageName = isBrowser ? document.title : null

  const contactForm = {
    submittedAt: Date.now(),
    fields: parsedFormFields,
    context: {
      hutk,
      pageUri,
      pageName,
      ipAddress,
    },
  }

  const PORTAL_ID = process.env.GATSBY_HUBSPOT_PORTAL_ID
  const FORM_ID = formId || process.env.GATSBY_HUBSPOT_FORM_ID

  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`
  return axios.post(url, JSON.stringify(contactForm), {
    headers: { 'content-type': 'application/json' },
  })
}
