import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'

import { ANIMATIONS } from '_config/home-variables'
import arrows from '_images/lotties/arrows.json'
import circles from '_images/lotties/circles.json'
import phone from '_images/lotties/phone.json'
import window from '_images/lotties/window.json'

import styles from './styles.module.css'

const animationTypes = {
  [ANIMATIONS.UIUX_DESIGN]: arrows,
  [ANIMATIONS.MOBILE_DEV]: phone,
  [ANIMATIONS.WEB_DEV]: window,
  [ANIMATIONS.NEXTGEN]: circles,
}

const ServiceIcons = ({ name }) => {
  const defaultOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animationTypes[name],
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    [name]
  )
  return (
    <div className={styles.serviceIcons}>
      <Lottie options={defaultOptions} height="100%" width="100%" />
    </div>
  )
}

ServiceIcons.propTypes = {
  name: PropTypes.string.isRequired,
}

export default ServiceIcons
