import React from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Container, Heading, Text, Button } from '_atoms'

import ViewportObserver from '../viewport-observer'

import styles from './styles.module.css'

const intlBreakLineValues = {
  span: (...chunks) => <span>{chunks}</span>,
  strong: (...chunks) => <strong>{chunks}</strong>,
  div: (...chunks) => (
    <>
      {chunks}
      <br />
    </>
  ),
}

const EmpoweringStartups = ({ scroll, onViewport }) => {
  return (
    <section className={styles.empoweringStartups}>
      <div className={styles.empoweringStartupsBackgroundImage} />
      <Container>
        <div className={styles.empoweringStartupsContent}>
          <Fade distance="20%" delay={100} bottom>
            <Heading className={styles.empoweringStartupsTitle}>
              <FormattedMessage values={intlBreakLineValues} id="home.empoweringStartups.title" />
            </Heading>
          </Fade>

          <Fade distance="20%" delay={200} bottom>
            <Text size="24" color="dark" className={styles.empoweringStartupsDescription}>
              <FormattedMessage
                values={intlBreakLineValues}
                id="home.empoweringStartups.description"
              />
            </Text>
          </Fade>
          <Fade distance="20%" delay={200} bottom>
            <Button
              href="/contact/"
              className={styles.empoweringStartupsButton}
              small
              type="primaryOutline"
            >
              <FormattedMessage id="home.empoweringStartups.cta" />
            </Button>
          </Fade>
        </div>
      </Container>
      {!!scroll && <ViewportObserver scroll={scroll} onViewport={onViewport} />}
    </section>
  )
}

EmpoweringStartups.propTypes = {
  scroll: PropTypes.number,
  onViewport: PropTypes.func,
}

EmpoweringStartups.defaultProps = {
  scroll: undefined,
  onViewport: undefined,
}

export default EmpoweringStartups
