import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { GenericContentSection } from '_molecules'
import Arrow from '_images/svgs/arrow.svg'
import { setCssVar } from '_utils/set-css-var'

import styles from './styles.module.css'

export const HeroSection = ({
  title,
  titleColor,
  titleClassname,
  text,
  textColor,
  textClassname,
  splitParagraphs,
  image,
  topContent,
  scrollDownElementId,
  backgroundColor,
  wrapperClassname,
}) => {
  const onScrollArrowClick = targetId =>
    typeof window !== 'undefined' &&
    document.getElementById(targetId).scrollIntoView({ behavior: 'smooth' })
  return (
    <>
      <div
        className={styles.mobileNavbarGap}
        {...setCssVar({ 'mobile-navbar-gap-bg': backgroundColor })}
      />
      <div className={classNames(styles.wrapper, wrapperClassname)}>
        <GenericContentSection
          direction="row-reverse"
          title={title}
          titleType="h1"
          titleColor={titleColor}
          titleClassname={classNames(styles.title, titleClassname)}
          text={text}
          textColor={textColor}
          textClassname={textClassname}
          splitParagraphs={splitParagraphs}
          topContent={topContent}
          bottomContent={
            scrollDownElementId && (
              <button
                className={styles.scrollButton}
                type="button"
                onClick={() => onScrollArrowClick(scrollDownElementId || '')}
              >
                <Arrow />
              </button>
            )
          }
          backgroundColor={backgroundColor}
          containerClassname={styles.container}
          columnsClassNames={['', styles.imageColumn]}
        >
          {image}
        </GenericContentSection>
      </div>
    </>
  )
}

HeroSection.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  titleClassname: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  textClassname: PropTypes.string,
  image: PropTypes.node,
  topContent: PropTypes.node,
  scrollDownElementId: PropTypes.string,
  backgroundColor: PropTypes.string,
  splitParagraphs: PropTypes.bool,
  wrapperClassname: PropTypes.string,
}

HeroSection.defaultProps = {
  image: null,
  titleColor: '',
  titleClassname: '',
  text: '',
  textColor: '',
  textClassname: '',
  topContent: null,
  scrollDownElementId: '',
  backgroundColor: '',
  splitParagraphs: false,
  wrapperClassname: '',
}
