import React from 'react'
import PropTypes from 'prop-types'

import { Image } from '_atoms'

import styles from './styles.module.css'

export const BlogCard = ({ title, link, linkText, image }) => {
  return (
    <div className={styles.container}>
      <Image src={image} alt={title} />
      <div className={styles.containerDescription}>
        <h3 className={styles.title}>{title}</h3>
        <a href={link} className={styles.link}>
          {linkText}
        </a>
      </div>
    </div>
  )
}

BlogCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default BlogCard
