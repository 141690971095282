import React from 'react'
import PropTypes from 'prop-types'

import { setCssVar } from '_utils/set-css-var'

import styles from './styles.module.css'

const LastColoredCharText = ({ content, color }) => {
  if (!color || typeof content !== 'string') return content

  const contentWithoutLastChar = content.slice(0, -1)
  const contentLastChar = content.slice(-1)

  return (
    <>
      {contentWithoutLastChar}
      <span className={styles.lastChar} {...setCssVar({ 'last-char-color': color })}>
        {contentLastChar}
      </span>
    </>
  )
}

LastColoredCharText.propTypes = {
  content: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
}

LastColoredCharText.defaultProps = {
  content: '',
  color: '',
}

export default LastColoredCharText
