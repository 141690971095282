import React from 'react'
import PropTypes from 'prop-types'
import StackGrid from 'react-stack-grid'
import classNames from 'classnames'

import { Container, Image, Text } from '_atoms'
import { useMobileView } from '_hooks'

import styles from './styles.module.css'

export const TwoColumnsCardGrid = ({ cards, cardClassName, headlineClassName, linkClassName }) => {
  const { isMobile } = useMobileView()

  return (
    <Container>
      <StackGrid columnWidth={isMobile ? '100%' : '50%'}>
        {cards.map((card, index) => (
          <div index={index} className={classNames(styles.card, cardClassName)}>
            <Image src={card.iconSrc} />
            <Text size="16" className={classNames(styles.cardHeadline, headlineClassName)}>
              {card.link ? (
                <a
                  href={card.link}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={classNames(styles.cardLink, linkClassName)}
                >
                  {card.headline}
                </a>
              ) : (
                <>{card.headline}</>
              )}
              {card.text}
            </Text>
          </div>
        ))}
      </StackGrid>
    </Container>
  )
}

TwoColumnsCardGrid.propTypes = {
  cards: PropTypes.object.isRequired,
  cardClassName: PropTypes.string,
  headlineClassName: PropTypes.string,
  linkClassName: PropTypes.string,
}

TwoColumnsCardGrid.defaultProps = {
  cardClassName: undefined,
  headlineClassName: undefined,
  linkClassName: undefined,
}

export default TwoColumnsCardGrid
