/* eslint-disable no-use-before-define */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Fade from 'react-reveal/Fade'

import {
  Button,
  BUTTON_STYLES,
  Container,
  Heading,
  HEADING_LEVELS,
  Text,
  LastColoredCharText,
} from '_atoms'
import { setCssVar } from '_utils/set-css-var'
import { nonBreakHypens } from '_utils/non-break-hypens'

import styles from './styles.module.css'

const GenericContentSection = ({
  sectionId,
  direction,
  backgroundColor,
  title,
  titleLastCharColor,
  titleType,
  titleColor,
  titleClassname,
  text,
  textColor,
  textClassname,
  buttonLabel,
  buttonType,
  buttonHref,
  buttonClassname,
  children,
  topContent,
  bottomContent,
  containerClassname,
  columnsClassNames,
  splitParagraphs,
}) => {
  const isRow = direction === 'row'
  const directionName = isRow ? 'row' : 'rowReverse'
  const isTextString = typeof text === 'string'

  const [firtColumnClassname, secondColumnClassname] = isRow
    ? columnsClassNames
    : columnsClassNames.reverse()

  return (
    <div
      id={sectionId}
      className={styles.wrapper}
      {...setCssVar({ 'generic-wrapper-bg': backgroundColor })}
    >
      <Container
        className={classNames(styles.container, styles[directionName], containerClassname)}
      >
        <Fade distance="10%" top>
          <div className={classNames(styles.column, firtColumnClassname)}>{children}</div>

          <div className={classNames(styles.column, secondColumnClassname)}>
            {topContent}
            <Heading
              bold
              className={classNames(styles.title, titleClassname)}
              type={titleType}
              {...setCssVar({ 'generic-title-color': titleColor })}
            >
              <LastColoredCharText content={nonBreakHypens(title)} color={titleLastCharColor} />
            </Heading>

            {text && <div className={styles.spacer} />}

            {text && isTextString && (
              <>
                <Text
                  splitParagraphs={splitParagraphs}
                  size={22}
                  className={classNames(styles.text, textClassname)}
                  {...setCssVar({ 'generic-text-color': textColor })}
                >
                  {text}
                </Text>
                <div className={styles.spacer} />
              </>
            )}

            {text && !isTextString ? text : null}

            {text || buttonLabel ? <div className={styles.spacer} /> : null}

            {buttonLabel && (
              <Button
                type={buttonType}
                isExternalLink
                href={buttonHref}
                className={buttonClassname}
              >
                {buttonLabel}
              </Button>
            )}
          </div>
        </Fade>
        {bottomContent}
      </Container>
    </div>
  )
}

GenericContentSection.propTypes = {
  sectionId: PropTypes.string,
  containerClassname: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'row-reverse']),
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  topContent: PropTypes.node,
  bottomContent: PropTypes.node,
  title: PropTypes.string.isRequired,
  titleClassname: PropTypes.string,
  titleLastCharColor: PropTypes.string,
  titleType: PropTypes.oneOf(HEADING_LEVELS),
  titleColor: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  textClassname: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonType: PropTypes.oneOf(BUTTON_STYLES),
  buttonHref: PropTypes.string,
  buttonClassname: PropTypes.string,
  columnsClassNames: PropTypes.arrayOf([PropTypes.string]),
  splitParagraphs: PropTypes.bool,
}

GenericContentSection.defaultProps = {
  sectionId: '',
  containerClassname: undefined,
  direction: 'row',
  backgroundColor: 'inherit',
  titleType: 'h4',
  titleClassname: '',
  titleLastCharColor: '',
  titleColor: 'inherit',
  text: '',
  textColor: 'inherit',
  textClassname: '',
  buttonLabel: '',
  buttonType: 'primary',
  buttonHref: '',
  buttonClassname: '',
  topContent: null,
  bottomContent: null,
  columnsClassNames: ['', ''],
  splitParagraphs: false,
}

export default GenericContentSection
